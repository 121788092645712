import React from "react"
import App, { Props as AppProps } from "./App"

export type Props = AppProps & {
}

export const Fragment = (props: Props) => {
  return (
      <App {...props} />
  )
}
