import React, { useEffect, useState } from "react";
import { initialize, activate, waitTillAvailable } from "@vp/ab-reader";

export const ExternalDemoComponentUsingABREader = (props) => {
  const [initializationError, setInitializationError] = useState("");
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
    initialize();
    waitTillAvailable(1000).then((initialized) => {
      if (!initialized) {
        setInitializationError("Couldn't initialize ab-reader");
      }
      setInitializationError("No error");
    });
  }, []);

  return (
    <>
      <strong>
        This is a component from a package using ab-reader library
      </strong>
      <br />
      Initialization error: {isClient ? initializationError : undefined}
      <br />
      Experiment from ab-reader:{" "}
      {isClient ? activate("metric_testing_aa") : undefined}
    </>
  );
};
