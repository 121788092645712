import { clientMount } from "@vp/ubik-fragment-react";
import type { MountWithRootOptions } from "@vp/ubik-fragment-types";
import React from "react";
import { Fragment as Root, type Props } from "../fragment";

export const mount: MountWithRootOptions<Props> = async (
  rootElement,
  ssrProps,
  rootOptions
) => {
  return clientMount(<Root {...ssrProps} />, rootElement, rootOptions);
};
