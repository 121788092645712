import React, { useState } from "react";
import { Card, FlexBox, Typography } from "@vp/swan";
import { useSwanStyleKeys } from "@vp/ubik-context";
import { AbReaderFromImportMap } from "./components/abReaderFromImportMap";
import { ExperimentFromContext } from "./components/experimentFromContext";
import { ExternalDemoComponentUsingABREader } from "external-component";
import { BundledExternalDemoComponentUsingABREader } from "bundled-external-component";

export type Props = {
  /**/
};

function App(props: Props) {
  useSwanStyleKeys(["core"]);

  return (
    <>
      <FlexBox>
        <Card>
          <Typography fontWeight="bold" mb={"3"} mr={"3"}>
            Hello World from Experimentation Demo fragment!
          </Typography>
          <FlexBox>
            <Card
              marginBottom={"4"}
              backgroundColor="holiday-green-1"
              darkMode
              bordered
            >
              <AbReaderFromImportMap></AbReaderFromImportMap>
            </Card>
          </FlexBox>
          <FlexBox>
            <Card marginBottom={"4"} backgroundColor="promo" bordered>
              <ExperimentFromContext></ExperimentFromContext>
            </Card>
          </FlexBox>
          <FlexBox>
            <Card marginBottom={"4"} backgroundColor="strong" darkMode bordered>
              <ExternalDemoComponentUsingABREader></ExternalDemoComponentUsingABREader>
            </Card>
          </FlexBox>
          <FlexBox>
            <Card
              marginBottom={"4"}
              backgroundColor={"accent"}
              darkMode
              bordered
            >
              <BundledExternalDemoComponentUsingABREader></BundledExternalDemoComponentUsingABREader>
            </Card>
          </FlexBox>
        </Card>
      </FlexBox>
    </>
  );
}

export default App;
